import { DropType } from "ds-flowty"
import { FlowNFTContract, FlowtyException } from "flowty-common"
import { useEffect, useState } from "react"
import { useLocation, useParams, useSearchParams } from "react-router-dom"
import { flowty } from "../../../config/config"
import { fetchSpecificFlowNFTContract } from "../../../services/firestore/nftIInfo"
import AuthStore from "../../../stores/AuthStore"
import { apiURL } from "../../../util/settings"
import { FLOW_IDENTIFIER } from "../../../util/tokens"

interface CollectionPageValues {
	collection: FlowNFTContract | null
	isLoading: boolean
	collectionAddress: string | undefined
	collectionName: string | undefined
	collectionDisplayName: string | undefined
	collectionDropData: DropType | undefined
	isDapper: boolean
	flowtyTokenBalance: number
	isLoadingDrop: boolean
	selectedTab: CollectionTabsType
	onSelectedTabChange: (tab: CollectionTabsType) => void
}

export type CollectionTabsType = "LISTINGS" | "ACTIVITY" | "DROP"

export const useCollectionPage = (
	authStore?: AuthStore
): CollectionPageValues => {
	const { collectionAddress, collectionName, resource } = useParams()
	const location = useLocation()
	const url =
		collectionName &&
		collectionAddress &&
		`${apiURL}/collection/${collectionAddress}/${collectionName}`
	const [collection, setCollection] = useState<FlowNFTContract | null>(null)
	const [collectionDropData, setCollectionDropData] = useState<DropType[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [isLoadingDrop, setIsLoadingDrop] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams()

	const currentParam =
		(searchParams?.get("page")?.toUpperCase() as CollectionTabsType) || null
	const [selectedTab, setSelectedTab] = useState<CollectionTabsType>(
		currentParam || "LISTINGS"
	)

	useEffect(() => {
		const hasPageParam =
			(searchParams?.get("page")?.toUpperCase() as CollectionTabsType) || null

		if (!hasPageParam) {
			setSelectedTab("LISTINGS")
		} else if (hasPageParam !== selectedTab) {
			setSelectedTab(hasPageParam)
		}
	}, [searchParams])

	const onSelectedTabChange = (tab: CollectionTabsType): void => {
		setSelectedTab(tab)
		searchParams.delete("type")
		if (tab === "DROP" || tab === "ACTIVITY") {
			searchParams.set("page", tab.toLowerCase())
			setSearchParams(searchParams)
		} else {
			searchParams.delete("page")
			setSearchParams(searchParams)
		}
	}

	const isDapper = authStore?.loggedUser?.isDapper || false
	const userAddress = authStore?.loggedUser?.addr

	const [collectionMap, setcollectionMap] = useState<
		Record<string, FlowNFTContract>
	>({})

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [location.pathname])

	useEffect(() => {
		if (url) {
			if (collectionMap[url]) {
				setCollection(oldCollect => {
					if (oldCollect?.address === collectionMap[url]?.address) {
						return oldCollect
					}
					return collectionMap[url]
				})
				return
			}

			setIsLoading(true)
			setCollection(null)

			fetchSpecificFlowNFTContract({ collectionAddress, collectionName })
				.then((res: FlowNFTContract | undefined) => {
					if (res) {
						setCollection(res || null)
						setcollectionMap(prevMap => ({
							...prevMap,
							[url]: res,
						}))
					}
				})
				.catch(err => {
					throw new FlowtyException(
						err,
						{
							url,
						},
						"useCollection"
					)
				})
				.then(() => {
					setIsLoading(false)
				})
		}
	}, [url, collectionMap])

	const collectionDisplayName = collection?.collectionDisplay?.name
		? collection.collectionDisplay.name
		: collectionName

	const flowtyTokenBalance =
		authStore?.loggedUser?.balance?.balances?.[FLOW_IDENTIFIER] ?? 0

	const getCollectionDropData = async (): Promise<void> => {
		if (!collectionAddress || !collectionName) {
			return
		}

		const nftResourceTypeIdentifier = `A.${collectionAddress.substring(
			2
		)}.${collectionName}.${resource || "NFT"}`

		try {
			setIsLoadingDrop(true)
			const dropData = await flowty.getAllDropDetails({
				minter: userAddress || undefined,
				nftResourceTypeIdentifier,
				paymentIdentifier: flowty.config.getIdentifier("FlowToken", "Vault"),
				quantity: 1,
			})

			if ((!dropData || dropData?.length === 0) && currentParam === "DROP") {
				onSelectedTabChange("LISTINGS")
			}
			const filteredDropData = dropData.filter((data: { nftType: string }) => {
				const [_, address, name] = data.nftType.split(".")
				return `0x${address}` === collectionAddress && name === collectionName
			})

			setCollectionDropData(filteredDropData)
			setIsLoadingDrop(false)
		} catch (e) {
			console.log("error on get collection drop data", e)
		}
	}

	useEffect(() => {
		getCollectionDropData()
	}, [userAddress])

	return {
		collection,
		collectionAddress,
		collectionDisplayName,
		collectionDropData: collectionDropData[0],
		collectionName,
		flowtyTokenBalance,
		isDapper,
		isLoading,
		isLoadingDrop,
		onSelectedTabChange,
		selectedTab,
	}
}
