import { OpensearchFlowNFT, SupportedTokens } from "flowty-common"
import { Dispatch, SetStateAction } from "react"
import Swal from "sweetalert2"
import { Flowty } from "flowty-sdk"
import { ListingState } from "../contexts/FlowtyListingModalContext/types/ListingModalContextTypes"
import { FlowtyListingFormValues } from "../FlowtyListingModal"

const IS_STORYBOOK = process.env.IS_STORYBOOK

interface ListingFnBuilderProps {
	listingType: string
	openSearchFlowNFT: OpensearchFlowNFT
	values: FlowtyListingFormValues
	ftPrivatePathIdentifier?: string
	nftProviderPathIdentifier: string
	ftReceiverAddress: string
	txAvailableCallback: (txId: string | null) => void
	setIsLoading: Dispatch<SetStateAction<ListingState>>
	setError: Dispatch<SetStateAction<ListingState>>
	setIsSealed: Dispatch<SetStateAction<ListingState>>
	transferWalletSelected: string
	mixPanelFn: (event: string, data: unknown) => void
	flowty: Flowty
}

export const listingFnBuilder = async ({
	listingType,
	openSearchFlowNFT,
	values,
	ftPrivatePathIdentifier = "",
	nftProviderPathIdentifier,
	ftReceiverAddress,
	txAvailableCallback,
	setIsLoading,
	setError,
	setIsSealed,
	mixPanelFn,
	transferWalletSelected,
	flowty,
}: ListingFnBuilderProps): Promise<undefined> => {
	switch (listingType) {
		case "storefront":
			try {
				setIsLoading((prevState: ListingState) => ({
					...prevState,
					sale: true,
				}))
				setError((prevState: ListingState) => ({
					...prevState,
					sale: false,
				}))
				setIsSealed((prevState: ListingState) => ({
					...prevState,
					sale: false,
				}))

				if (IS_STORYBOOK) {
					setTimeout(() => {
						setIsLoading((prevState: ListingState) => ({
							...prevState,
							sale: false,
						}))
						if (openSearchFlowNFT.type === "ERROR_TYPE_MOCK") {
							setError((prevState: ListingState) => ({
								...prevState,
								sale: true,
							}))
							return
						} else {
							setIsSealed((prevState: ListingState) => ({
								...prevState,
								sale: true,
							}))
						}
					}, 3000)
					return
				}

				const token = flowty.tokens.getTokenInfo(
					values?.tokenType as SupportedTokens
				)
				const response = await flowty.createStorefrontListing({
					buyer: null,
					expiry: Number(values.storefrontListingDuration),
					ftReceiverAddress: ftReceiverAddress,
					nftData: {
						contractAddress: openSearchFlowNFT?.contractAddress,
						contractName: openSearchFlowNFT?.contractName,
						id: openSearchFlowNFT?.id.toString(),
						nftOwner: openSearchFlowNFT?.owner,
						type: openSearchFlowNFT?.type,
					},
					nftProviderPathIdentifier: nftProviderPathIdentifier,
					salePrice: Number(values?.saleListingValue),
					token,
					txAvailableCallback: txAvailableCallback,
				})
				if (response?.status === 4) {
					setIsSealed((prevState: ListingState) => ({
						...prevState,
						sale: true,
					}))
					setIsLoading((prevState: ListingState) => ({
						...prevState,
						sale: false,
					}))
					mixPanelFn(`Successful storefront listing transaction`, {
						expiry: Number(values.storefrontListingDuration),
						listingData: openSearchFlowNFT,
						salePrice: Number(values?.saleListingValue),
						token: values?.tokenType,
					})
					return
				}
				if (
					(response?.status !== 4 && response?.toString()?.includes("Error")) ||
					response?.toString()?.includes("Declined")
				) {
					throw new Error(response)
				}
			} catch (e) {
				setError((prevState: ListingState) => ({
					...prevState,
					sale: true,
				}))
				setIsLoading((prevState: ListingState) => ({
					...prevState,
					sale: false,
				}))
				if (e?.toString()?.includes("Popup failed to open")) {
					await Swal.fire({
						background: "#031021",
						color: "#FFFFFF",
						confirmButtonColor: "#FF6969",
						icon: "error",
						showConfirmButton: true,
						text: "Pop-up blocker is enabled. Please allow pop-ups and try again.",
						timer: 5000,
					})
				}
				mixPanelFn(`Error storefront listing transaction`, {
					err: e,
					expiry: Number(values.storefrontListingDuration),
					listingData: openSearchFlowNFT,
					salePrice: Number(values?.saleListingValue),
					token: values?.tokenType,
				})
			}
			break
		case "loan":
			try {
				setIsLoading((prevState: ListingState) => ({
					...prevState,
					loan: true,
				}))
				setError((prevState: ListingState) => ({
					...prevState,
					loan: false,
				}))
				setIsSealed((prevState: ListingState) => ({
					...prevState,
					loan: false,
				}))

				if (IS_STORYBOOK) {
					setTimeout(() => {
						setIsLoading((prevState: ListingState) => ({
							...prevState,
							loan: false,
						}))
						if (openSearchFlowNFT.type === "ERROR_TYPE_MOCK") {
							setError((prevState: ListingState) => ({
								...prevState,
								loan: true,
							}))
							return
						} else {
							setIsSealed((prevState: ListingState) => ({
								...prevState,
								loan: true,
							}))
						}
					}, 3000)
					return
				}

				const tokenInfo = flowty.tokens.getTokenInfo(
					values?.tokenType as SupportedTokens
				)

				const response = await flowty.createLoanListing({
					ftPrivatePathIdentifier: ftPrivatePathIdentifier,
					ftReceiverAddress: ftReceiverAddress,
					loanAmount: +values?.amountToBorrow,
					loanAutoRepaymentEnabled: values?.loanEnableAutoRepayment,
					loanDuration: +values?.loanDuration,
					loanExpiresAfterDays: Number(values.listingDuration),
					loanInterestRate:
						+values?.amountToRepay / +values?.amountToBorrow - 1,
					nftData: {
						contractAddress: openSearchFlowNFT?.contractAddress,
						contractName: openSearchFlowNFT?.contractName,
						id: openSearchFlowNFT?.id.toString(),
						nftOwner: openSearchFlowNFT?.owner,
						type: openSearchFlowNFT?.type,
					},
					nftProviderPathIdentifier: nftProviderPathIdentifier,
					token: tokenInfo,
					txAvailableCallback: txAvailableCallback,
				})
				if (response?.status === 4) {
					setIsSealed((prevState: ListingState) => ({
						...prevState,
						loan: true,
					}))
					setIsLoading((prevState: ListingState) => ({
						...prevState,
						loan: false,
					}))
					mixPanelFn(`Successful loan listing transaction`, {
						listingData: openSearchFlowNFT,
						loanAmount: values?.amountToBorrow,
						loanAutoRepaymentEnabled: values?.loanEnableAutoRepayment,
						loanDuration: values?.loanDuration,
						loanExpiresAfterDays: 30,
						loanInterestRate:
							+values?.amountToRepay / +values?.amountToBorrow - 1,
						token: values?.tokenType,
					})
					return
				}
				if (
					(response?.status !== 4 && response?.toString()?.includes("Error")) ||
					response?.toString()?.includes("Declined")
				) {
					throw new Error(response)
				}
			} catch (e) {
				setError((prevState: ListingState) => ({
					...prevState,
					loan: true,
				}))
				setIsLoading((prevState: ListingState) => ({
					...prevState,
					loan: false,
				}))
				if (e?.toString()?.includes("Popup failed to open")) {
					await Swal.fire({
						background: "#031021",
						color: "#FFFFFF",
						confirmButtonColor: "#FF6969",
						icon: "error",
						showConfirmButton: true,
						text: "Pop-up blocker is enabled. Please allow pop-ups and try again.",
						timer: 5000,
					})
				}
				mixPanelFn(`Error loan listing transaction`, {
					err: e,
					listingData: openSearchFlowNFT,
					loanAmount: values?.amountToBorrow,
					loanAutoRepaymentEnabled: values?.loanEnableAutoRepayment,
					loanDuration: values?.loanDuration,
					loanExpiresAfterDays: 30,
					loanInterestRate:
						+values?.amountToRepay / +values?.amountToBorrow - 1,
					token: values?.tokenType,
				})
			}
			break
		case "rental":
			try {
				setIsLoading((prevState: ListingState) => ({
					...prevState,
					rent: true,
				}))
				setError((prevState: ListingState) => ({
					...prevState,
					rent: false,
				}))
				setIsSealed((prevState: ListingState) => ({
					...prevState,
					rent: false,
				}))

				if (IS_STORYBOOK) {
					setTimeout(() => {
						setIsLoading((prevState: ListingState) => ({
							...prevState,
							rent: false,
						}))
						if (openSearchFlowNFT.type === "ERROR_TYPE_MOCK") {
							setError((prevState: ListingState) => ({
								...prevState,
								rent: true,
							}))
							return
						} else {
							setIsSealed((prevState: ListingState) => ({
								...prevState,
								rent: true,
							}))
						}
					}, 3000)
					return
				}

				const tokenMetadata = flowty.tokens.getTokenInfo(
					values?.tokenType as SupportedTokens
				)
				const response = await flowty.createRentListing({
					ftReceiverAddress: ftReceiverAddress,
					nftData: {
						contractAddress: openSearchFlowNFT?.contractAddress,
						contractName: openSearchFlowNFT?.contractName,
						id: openSearchFlowNFT?.id.toString(),
						nftOwner: openSearchFlowNFT?.owner,
						type: openSearchFlowNFT?.type,
					},
					nftProviderPathIdentifier: nftProviderPathIdentifier,
					rentalDeposit: +values?.refundableDeposit,
					rentalExpiresAfterDays: Number(values.rentalListingDuration),
					rentalFee: +values?.rentalFee,
					rentalTerm: +values?.rentalDuration,
					renterAddress: values?.privateListingAddr,
					token: tokenMetadata,
					txAvailableCallback: txAvailableCallback,
				})
				if (response?.status === 4) {
					setIsSealed((prevState: ListingState) => ({
						...prevState,
						rent: true,
					}))
					setIsLoading((prevState: ListingState) => ({
						...prevState,
						rent: false,
					}))
					mixPanelFn(`Successful rental listing transaction`, {
						listingData: openSearchFlowNFT,
						rentalDeposit: values?.refundableDeposit,
						rentalFee: values?.rentalFee,
						rentalTerm: values?.rentalDuration,
						token: values?.tokenType,
					})
					return
				}
				if (
					(response?.status !== 4 && response?.toString()?.includes("Error")) ||
					response?.toString()?.includes("Declined")
				) {
					throw new Error(response)
				}
			} catch (e) {
				setError((prevState: ListingState) => ({
					...prevState,
					rent: true,
				}))
				setIsLoading((prevState: ListingState) => ({
					...prevState,
					rent: false,
				}))
				if (e?.toString()?.includes("Popup failed to open")) {
					await Swal.fire({
						background: "#031021",
						color: "#FFFFFF",
						confirmButtonColor: "#FF6969",
						icon: "error",
						showConfirmButton: true,
						text: "Pop-up blocker is enabled. Please allow pop-ups and try again.",
						timer: 5000,
					})
				}
				mixPanelFn(`Error rental listing transaction`, {
					err: e,
					listingData: openSearchFlowNFT,
					rentalDeposit: values?.refundableDeposit,
					rentalFee: values?.rentalFee,
					rentalTerm: values?.rentalDuration,
					token: values?.tokenType,
				})
			}
			break
		case "transfer":
			try {
				setIsLoading((prevState: ListingState) => ({
					...prevState,
					transfer: true,
				}))
				setError((prevState: ListingState) => ({
					...prevState,
					transfer: false,
				}))
				setIsSealed((prevState: ListingState) => ({
					...prevState,
					transfer: false,
				}))

				if (IS_STORYBOOK) {
					setTimeout(() => {
						setIsLoading((prevState: ListingState) => ({
							...prevState,
							transfer: false,
						}))
						if (openSearchFlowNFT.type === "ERROR_TYPE_MOCK") {
							setError((prevState: ListingState) => ({
								...prevState,
								transfer: true,
							}))
							return
						} else {
							setIsSealed((prevState: ListingState) => ({
								...prevState,
								transfer: true,
							}))
						}
					}, 3000)
					return
				}

				const response = await flowty.transferNft({
					nftData: {
						contractAddress: openSearchFlowNFT?.contractAddress,
						contractName: openSearchFlowNFT?.contractName,
						id: openSearchFlowNFT?.id.toString(),
						nftOwner: openSearchFlowNFT?.owner,
						type: openSearchFlowNFT?.type,
					},
					nftProviderPathIdentifier: nftProviderPathIdentifier,
					transferRecipient:
						transferWalletSelected !== ""
							? transferWalletSelected
							: values?.transferReceiver,
					txAvailableCallback: txAvailableCallback,
				})
				if (response?.status === 4) {
					setIsSealed((prevState: ListingState) => ({
						...prevState,
						transfer: true,
					}))
					setIsLoading((prevState: ListingState) => ({
						...prevState,
						transfer: false,
					}))
					mixPanelFn(`Successful transfer listing transaction`, {
						listingData: openSearchFlowNFT,
						transferRecipient: values?.transferReceiver,
					})
					return
				}
				if (
					(response?.status !== 4 && response?.toString()?.includes("Error")) ||
					response?.toString()?.includes("Declined")
				) {
					throw new Error(response)
				}
			} catch (e) {
				setError((prevState: ListingState) => ({
					...prevState,
					transfer: true,
				}))
				setIsLoading((prevState: ListingState) => ({
					...prevState,
					transfer: false,
				}))
				if (e?.toString()?.includes("Popup failed to open")) {
					await Swal.fire({
						background: "#031021",
						color: "#FFFFFF",
						confirmButtonColor: "#FF6969",
						icon: "error",
						showConfirmButton: true,
						text: "Pop-up blocker is enabled. Please allow pop-ups and try again.",
						timer: 5000,
					})
				}
				mixPanelFn(`Error transfer listing transaction`, {
					err: e,
					listingData: openSearchFlowNFT,
					transferRecipient: values?.transferReceiver,
				})
			}
			break
		default:
			return
	}
}
