import { returnTsSpecialTraits, TsSpecialIcons } from "ds-flowty"
import {
	getImageURL,
	nftTypeAndIdToLocationData,
	OpensearchFlowNFT,
} from "flowty-common"
import { AccountSummaries } from "flowty-sdk"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Oval as OvalLoader } from "react-loader-spinner"
import { Tooltip } from "react-tooltip"
import { ReactComponent as LockClosedIcon } from "../../../assets/icons/lock-closed.svg"
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus-sm.svg"
import { ReactComponent as BulkCheck } from "../../../assets/media/bulk-check.svg"
import ViewOnly from "../../../assets/media/hc/viewOnly.png"
import noImage from "../../../assets/media/image_not_available.svg"
import { ReactComponent as VerifiedCheck } from "../../../assets/media/verifiedCheck.svg"
import { useNftDisplayViewContext } from "../../../contexts/NftDisplayViewContext"
import { addFallbackImage } from "../../../util/images"
import { playVideo } from "../../../util/playVideo"
import { isFlowRewards } from "../../../util/settings"
import { useOpenSearchContext } from "../../OpenSearch/OpenSearchConnector"
import { DiscountIndicator } from "../FMV/DiscountIndicator"
import FallbackImage from "../FallbackImage"
import { CardCountdownTimer } from "./components/CardCountdownTimer"
import { NftCardOrderDisplay } from "./components/NftCardOrderDisplay"
import { WalletImageComponent } from "./components/WalletImageComponent"

interface NftCardProps {
	nft: OpensearchFlowNFT
	hasProvider: boolean | null
	handleClick: (
		_:
			| React.MouseEvent<HTMLDivElement, MouseEvent>
			| React.KeyboardEvent<HTMLDivElement>
	) => void
	bulkSelected: boolean
	bulkDisabled: boolean
	didImageLoadFail: boolean
	discountPercent: number
	isLoading: boolean
	isLocked: boolean
	isCatalog: boolean
	accountSummaries: AccountSummaries | undefined
	publicAccountView: {
		addr: string | undefined | null
		avatar: string | undefined | null
		userName: string | undefined | null
	}
	bulkListActive: boolean
	profilePage: boolean
}

export const NftCard: React.FunctionComponent<NftCardProps> = ({
	nft,
	hasProvider,
	bulkSelected,
	bulkDisabled,
	handleClick,
	discountPercent,
	isLoading,
	isLocked,
	isCatalog,
	accountSummaries,
	publicAccountView,
	bulkListActive,
}) => {
	const [isMp4, setIsMp4] = useState<boolean | null>(null)
	const [onVideoError, setOnVideoError] = useState<boolean>(false)
	const assetUrl = getImageURL(nft.card?.images?.[0]?.url ?? noImage)
	const isChild = Object.keys(accountSummaries || {}).length > 1 || false
	const [isCardOnHover, setIsCardOnHover] = useState<boolean>(false)
	const isCollectionRoute = window.location.pathname.includes("/collection")

	const { view } = useNftDisplayViewContext()

	const { isFilterHidden } = useOpenSearchContext()

	const shouldTruncate = isLocked && view !== "large"

	const maxChars = 26

	const isProfilePage = location.pathname.includes("profile")

	const specialTsTraits = useMemo(
		() => returnTsSpecialTraits({ traits: nft.nftView?.traits?.traits ?? [] }),
		[nft.nftView?.traits?.traits]
	)

	const renderImage = useMemo(() => {
		if (!isMp4) {
			return (
				<div className='relative mx-auto'>
					<div className='flex flex-row absolute top-2 left-2 md:top-4 md:left-4'>
						<TsSpecialIcons specialTsTraits={specialTsTraits} />
					</div>
					<img
						src={
							isFlowRewards(nft.type) ? nft.card?.images?.[0]?.url : assetUrl
						}
						onError={() => {
							setIsMp4(true)
						}}
						alt={`${nft?.card?.title} asset`}
						className={`${
							isFlowRewards(nft.type) ? "object-contain" : "object-cover"
						} aspect-square w-full`}
					/>
				</div>
			)
		}

		if (onVideoError) {
			return (
				<div className='relative mx-auto'>
					<div className='flex flex-row absolute top-2 left-2 md:top-4 md:left-4'>
						<TsSpecialIcons specialTsTraits={specialTsTraits} />
					</div>
					<FallbackImage
						collectionAddress={
							nft.contractAddress || nft.card.collectionAddress || ""
						}
						collectionName={nft.contractName || nft.card.collectionName || ""}
						onError={e => {
							addFallbackImage(e)
							setIsMp4(true)
						}}
						className='object-cover aspect-square w-[152px] sm:w-[304px]'
					/>
				</div>
			)
		}

		if (isMp4) {
			return (
				<video
					muted
					loop
					playsInline
					preload='auto'
					autoPlay={false}
					className='object-cover aspect-square w-[152px] sm:w-[304px]'
					onMouseEnter={event => playVideo(event)}
					onMouseLeave={event => {
						// eslint-disable-next-line xss/no-mixed-html
						const videoElement = event.target as HTMLMediaElement
						videoElement.pause()
						videoElement.currentTime = 0
					}}
					onError={() => {
						setOnVideoError(true)
					}}
				>
					<source type='video/mp4' src={assetUrl}></source>
				</video>
			)
		}
	}, [
		onVideoError,
		isMp4,
		addFallbackImage,
		setIsMp4,
		setOnVideoError,
		assetUrl,
	])

	const titleRef = useRef<HTMLParagraphElement>(null)

	const [titleNumberLines, setTitleNumberLines] = useState(0)

	const checkNumberOfLines = (): void => {
		const node = titleRef.current
		if (node) {
			const computedStyle = window.getComputedStyle(node)
			const lineHeight = parseFloat(computedStyle.lineHeight)
			const numberOfLines = Math.ceil(node.scrollHeight / lineHeight)
			setTitleNumberLines(numberOfLines)
		}
	}

	useEffect(() => {
		const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1
		if (isFirefox) {
			checkNumberOfLines()
		}
	})

	useEffect(() => {
		checkNumberOfLines()
		window.addEventListener("resize", checkNumberOfLines)
		return () => window.removeEventListener("resize", checkNumberOfLines)
	}, [nft?.card?.title, nft?.card?.num, isFilterHidden])

	const locationData = nftTypeAndIdToLocationData(
		nft?.type || "",
		nft?.id || ""
	)

	return (
		<a
			href={`/asset/${locationData.contract.address}/${locationData.contract.name}/${locationData.resourceName}/${locationData.nftID}`}
		>
			<div
				onMouseEnter={() => {
					setIsCardOnHover(true)
				}}
				onMouseLeave={() => {
					setIsCardOnHover(false)
				}}
				onClick={event => handleClick(event)}
				onKeyDown={event => {
					if (event.key === "Enter") {
						handleClick(event)
					}
				}}
				data-testid='nft-card'
				className={`nftCard relative self-center justify-self-center flex flex-col p-[16px] rounded-lg nft-card-shadow cursor-pointer w-auto max-sm:w-full
			${
				bulkSelected
					? "border-[1.2px] !border-[#04E5A3] border-opacity-100 hover:border-[#04E5A3]"
					: "border-opacity-60 border-[0.8px] border-[#7c7c7c]"
			}
			${bulkListActive && bulkDisabled && "opacity-50"}`}
				style={{ maxWidth: "500px", minWidth: "240px" }}
			>
				{nft.orders?.[0] && nft.orders?.[0].listingKind !== "storefront" && (
					<CardCountdownTimer blockTimestamp={nft.orders?.[0].blockTimestamp} />
				)}
				{!isLoading &&
					nft.orders[0] &&
					nft.orders[0].listingKind === "storefront" && (
						<div className='absolute -top-1 right-[8%] lg:right-[10%] z-10'>
							<DiscountIndicator
								percent={discountPercent}
								source={`${nft.orders[0].valuations?.blended.source}`}
								tooltip
							/>
						</div>
					)}

				<div className='sm:hover:text-white sm:peer-hover:text-white '>
					<div className='flex sm:flex-col-reverse mb-2 sm:mb-1'>
						<div className='grid grid-cols-3 sm:grid-cols-1 max-sm:w-full'>
							{isLoading ? (
								<>
									<div
										className={`hidden sm:flex cursor-wait my-[0.7rem] bg-gray-400 justify-center items-center animate-pulse object-cover aspect-square w-[152px] sm:w-full h-[304px]`}
									></div>
									<div className='sm:hidden cursor-wait rounded bg-gray-400 flex justify-center items-center animate-pulse'></div>
								</>
							) : (
								<div className='relative sm:my-3 isolate'>
									{bulkListActive && (
										<div
											className={`absolute flex items-center justify-center right-1 top-1 w-[36px] h-[36px] bg-[#ffffff33] rounded-full backdrop-blur-[20px]
				${bulkSelected && "border-[1px] border-[#04E5A3] hover:border-[#04E5A3]"}
				${
					isCardOnHover &&
					!bulkSelected &&
					"border-[1px] border-[#9D9D9D] hover:border-[#9D9D9D]"
				}
				`}
										>
											{bulkSelected ? <BulkCheck /> : <PlusIcon />}
										</div>
									)}

									{renderImage}
								</div>
							)}
							{isLoading ? (
								<div className='flex flex-col sm:mb-2 pl-3 sm:pl-0 col-span-2 sm:order-first'>
									<div className='flex flex-row items-center justify-start bg-gray-400 animate-pulse cursor-wait h-6' />
									<div className='flex flex-row items-center justify-start bg-gray-400 animate-pulse cursor-wait mt-2 h-4' />
									<div className='flex flex-row items-center justify-start bg-gray-400 animate-pulse cursor-wait mt-2 h-4' />
								</div>
							) : (
								<div className='flex flex-col pl-3 sm:pl-0 col-span-2 sm:order-first'>
									<div className='flex flex-row relative'>
										<div
											className={`${isLocked ? "max-w-[73%]" : "max-w-[80%]"}`}
										>
											{nft?.card?.additionalDetails ? (
												<p className='text-[1.125rem] max-w-[240px] font-montserrat font-extrabold truncate pointer-events-none uppercase'>
													{nft?.card?.title}
												</p>
											) : (
												<p
													className={`font-montserrat font-extrabold uppercase text-[1.125rem] overflow-hidden text-ellipsis ${
														titleNumberLines > 3 && "h-[6rem] custom-truncate"
													}`}
													ref={titleRef}
												>
													{nft?.card?.title}
													{nft?.card?.num &&
														!nft?.card?.title.includes("#") && (
															<span className='font-montserrat font-extrabold uppercase text-[1.125rem]'>
																{" "}
																#{nft?.card?.num}
															</span>
														)}
												</p>
											)}
										</div>
										<div className='flex flex-col items-center justify-center space-y-2 flex-shrink-0 absolute top-1 right-0'>
											<div className='flex flex-row sm:flex-col justify-center items-center gap-1'>
												{isCatalog && !isCollectionRoute && (
													<>
														<Tooltip
															id={`${nft.uuid}-verified-tooltip`}
															style={{
																backgroundColor: "white",
																color: "#031021",
																fontSize: "0.75rem",
															}}
														/>

														<VerifiedCheck
															data-tooltip-id={`${nft.uuid}-verified-tooltip`}
															data-tooltip-content={`Verified Collection from the Flow NFT catalog`}
															data-tooltip-place='right'
															width={18}
															height={18}
														/>
													</>
												)}
												{isLocked && (
													<LockClosedIcon className='h-5 w-5 fill-white' />
												)}

												{isChild && (
													<WalletImageComponent
														accountSummaries={accountSummaries}
														nft={nft}
														publicAccountView={publicAccountView}
													/>
												)}
												{hasProvider === null && isProfilePage && (
													<OvalLoader
														wrapperClass={"p-0"}
														height={15}
														width={15}
													/>
												)}
												{hasProvider === false && isProfilePage && (
													<>
														<Tooltip
															id={`${nft.uuid}-view-only-tooltip`}
															style={{
																backgroundColor: "white",
																color: "#031021",
																fontSize: "0.75rem",
																zIndex: 10,
															}}
														/>
														<img
															data-tooltip-id={`${nft.uuid}-view-only-tooltip`}
															data-tooltip-content='This profile has view-only access to this item'
															data-tooltip-place='right'
															src={ViewOnly}
															alt='Read Only Icon'
															width={16}
															height={16}
														/>
													</>
												)}
											</div>
										</div>
									</div>
									{nft?.card?.additionalDetails ? (
										<>
											{nft?.card?.additionalDetails ? (
												nft?.card?.additionalDetails?.map(detail => (
													<div
														key={detail}
														className='flex flex-row items-center justify-start mt-2 h-6 truncate'
													>
														<p className='font-montserrat text-[#8B8B8B] text-sm font-semibold last:border-none border-r-2 border-[#8B8B8B] pointer-events-none first:pl-0 pr-2 pl-2'>
															{detail.length > maxChars && shouldTruncate
																? detail.slice(0, maxChars) + "..."
																: detail}
														</p>
													</div>
												))
											) : (
												<div className='flex flex-row items-center justify-start mt-2 h-6'></div>
											)}
										</>
									) : (
										<>
											{titleNumberLines < 3 && (
												<>
													<div className='flex flex-row items-center justify-start mt-2 h-6'>
														{nft?.card?.headerTraits &&
															nft?.card?.headerTraits.map(trait => (
																<p
																	key={trait.displayType}
																	className='font-montserrat text-[#8B8B8B] text-sm font-semibold last:border-none border-r-2 border-[#8B8B8B] pointer-events-none first:pl-0 pr-2 pl-2'
																>
																	{trait.value}
																</p>
															))}
													</div>
													{titleNumberLines <= 1 && (
														<div className='flex flex-row items-center justify-start mt-2 h-6'></div>
													)}
												</>
											)}
										</>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
				<NftCardOrderDisplay
					order={nft?.orders?.[0] || null}
					isLoading={isLoading}
					nft={nft}
				/>
			</div>
		</a>
	)
}
