import { connectWallet, DropPhaseProps, Flowty } from "flowty-sdk"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { removeSpecialChars } from "../components/FormView/components/ConfirmationFormStep/components/CollectionConfirmationFormStep"
import { FormTabs } from "../types/FlowtyCreatorHubContextTypes"
import { FlowtyCreatorHubFormValues } from "../types/FlowtyCreatorHubTypes"
import { cleanCreatorHubCache } from "../utils/creatorHubCacheFn"

interface UseCreatorHubValues {
	collectionPageURL: string | null
	collectionPageTwitterURL: string | null
	collectionState: CreateCollectionStateType
	directToDropPage: () => void
	modalNavProps: CreatorHubNavProps
	onCloseModal: () => void
	transactionID: string | null
	errMessage: string | null
}

interface UseCreatorHubModalProps {
	changeSelectedFormTab: (tab: FormTabs) => void
	creatorHubId?: string
	loggedIsDapper?: boolean
	isLoggedUser?: boolean
	flowty: Flowty
	logOutFn: () => void
	mixPanelFn: (event: string, data: unknown) => void
	onCloseModal: () => void
	resetForm: () => void
	uploadImageFn?: (file: File) => Promise<{ cid: string } | null>
	values: FlowtyCreatorHubFormValues
}

type CreateCollectionStateType = {
	isError: boolean
	isCreating: boolean
	isSuccess: boolean
}

type CreatorHubNavPropsType = {
	text: string | React.ReactNode
	onClick: () => void
	disabled?: boolean
	bgColor: "primary" | "danger" | "white"
}

export type CreatorHubNavProps =
	| CreatorHubNavPropsType
	| CreatorHubNavPropsType[]
	| undefined

export const useCreatorHubModal = ({
	creatorHubId,
	changeSelectedFormTab,
	loggedIsDapper = false,
	isLoggedUser = false,
	flowty,
	logOutFn,
	mixPanelFn,
	onCloseModal,
	resetForm,
	uploadImageFn,
	values,
}: UseCreatorHubModalProps): UseCreatorHubValues => {
	const navigate = useNavigate()
	const [transactionID, setTransactionID] = useState<string | null>(null)
	const [collectionPageURL, setCollectionPageURL] = useState<string | null>(
		null
	)
	const [collectionPageTwitterURL, setCollectionPageTwitterURL] = useState<
		string | null
	>(null)

	const [errMessage, setErrMessage] = useState<string | null>(null)
	const [collectionState, setCollectionState] =
		useState<CreateCollectionStateType>({
			isCreating: false,
			isError: false,
			isSuccess: false,
		})

	const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false)

	const signIn = async (): Promise<void> => {
		setIsLoggingIn(true)
		logOutFn()
		await connectWallet()
	}

	useEffect(() => {
		if (isLoggedUser && isLoggingIn) {
			setIsLoggingIn(false)
		}
	}, [isLoggedUser, isLoggingIn])

	const contractName = removeSpecialChars(values.collectionName)

	const uploadImages = async (): Promise<void> => {
		if (process.env.IS_STORYBOOK) {
			if (creatorHubId === "ERROR_TYPE_MOCK") {
				setCollectionState(prev => ({
					...prev,
					isCreating: true,
					isError: false,
				}))
				setTimeout(
					() =>
						setCollectionState(prev => ({
							...prev,
							isCreating: false,
							isError: true,
						})),
					3000
				)
			} else {
				setCollectionState(prev => ({ ...prev, isCreating: true }))
				setTimeout(
					() =>
						setCollectionState(prev => ({
							...prev,
							isCreating: false,
							isSuccess: true,
						})),
					3000
				)
			}
		}

		if (uploadImageFn) {
			let nftImageCid: string | undefined | null = values.nftImage?.fileCid
			let collectionThumbnailCid: string | undefined | null =
				values.nftThumbnailImage?.fileCid
			let collectionBannerCid: string | undefined | null =
				values.nftBannerImage?.fileCid
			let dropImageCid: string | undefined | null =
				values.dropThumbnailImage?.fileCid

			setCollectionState(prev => ({
				...prev,
				isCreating: true,
				isError: false,
			}))

			try {
				if (
					Boolean(values.nftBannerImage?.fileCid) &&
					values.nftImage?.file &&
					values.nftImage?.fileBase64 ===
						values.nftThumbnailImage?.fileBase64 &&
					values.nftImage?.fileBase64 === values.dropThumbnailImage?.fileBase64
				) {
					const nftImageCidResponse = await uploadImageFn(values.nftImage?.file)
					nftImageCid = nftImageCidResponse?.cid
					collectionThumbnailCid = nftImageCidResponse?.cid
					dropImageCid = nftImageCidResponse?.cid
				} else {
					if (
						values.nftImage?.file &&
						values.nftImage?.file.name !== "FLOWTY_DEFAULT_IMAGE"
					) {
						const nftImageCidResponse = await uploadImageFn(
							values.nftImage?.file
						)
						nftImageCid = nftImageCidResponse?.cid
					}
					if (
						values.nftThumbnailImage?.file &&
						values.nftThumbnailImage?.file.name !== "FLOWTY_DEFAULT_IMAGE"
					) {
						const collectionThumbnailCidResponse = await uploadImageFn(
							values.nftThumbnailImage?.file
						)
						collectionThumbnailCid = collectionThumbnailCidResponse?.cid
					}
					if (
						!Boolean(values.nftBannerImage?.fileCid) &&
						values.nftBannerImage?.file &&
						values.nftBannerImage?.file.name !== "FLOWTY_DEFAULT_IMAGE"
					) {
						const collectionBannerCidResponse = await uploadImageFn(
							values.nftBannerImage?.file
						)
						collectionBannerCid = collectionBannerCidResponse?.cid
					}

					if (
						values.dropThumbnailImage?.file &&
						values.dropThumbnailImage?.file.name !== "FLOWTY_DEFAULT_IMAGE"
					) {
						const dropImageCidResponse = await uploadImageFn(
							values.dropThumbnailImage?.file
						)
						dropImageCid = dropImageCidResponse?.cid
					}
				}
			} catch (e) {
				console.log("ERROR uploading creator hub images", e)
				mixPanelFn("error uploading images", {
					collectionBannerCid,
					collectionThumbnailCid,
					dropImageCid,
					error: e,
					nftImageCid,
				})
				setCollectionState(prev => ({
					...prev,
					isCreating: false,
					isError: true,
				}))
			}

			if (
				Boolean(nftImageCid) &&
				Boolean(collectionThumbnailCid) &&
				Boolean(collectionBannerCid) &&
				Boolean(dropImageCid)
			) {
				submitNewCollection(
					collectionBannerCid,
					collectionThumbnailCid,
					dropImageCid,
					nftImageCid
				)
			} else {
				mixPanelFn("ERROR - Collection not submited, missing image", {
					collectionBannerCid,
					collectionThumbnailCid,
					dropImageCid,
					nftImageCid,
				})
				onCloseModal()
				changeSelectedFormTab(FormTabs.CustomizeCollectionFirstTab)
			}
		}
	}

	const submitNewCollection = async (
		collectionBanner?: string | null,
		collectionThumbnail?: string | null,
		dropImage?: string | null,
		nftImage?: string | null
	): Promise<void> => {
		if (process.env.IS_STORYBOOK) {
			if (creatorHubId === "ERROR_TYPE_MOCK") {
				setCollectionState(prev => ({
					...prev,
					isCreating: true,
					isError: false,
				}))
				setTimeout(
					() =>
						setCollectionState(prev => ({
							...prev,
							isCreating: false,
							isError: true,
						})),
					3000
				)
			} else {
				setCollectionState(prev => ({ ...prev, isCreating: true }))
				setTimeout(
					() =>
						setCollectionState(prev => ({
							...prev,
							isCreating: false,
							isSuccess: true,
						})),
					3000
				)
			}
			return
		}

		const collectionDetails = {
			collectionBannerImageMediaType: "image/png",
			collectionDescription: values.collectionDescription,
			collectionDiscordHandle: values.discordHandle,
			collectionExternalURL: values.collectionWebsiteURL,
			collectionInstagramHandle: values.instagramHandle,
			collectionName: values.collectionName,
			collectionPageBannerImage:
				values.nftBannerImage?.fileCid ?? String(collectionBanner),
			collectionRoyalty: Number(values.royalty),
			collectionSquareImage:
				values.nftThumbnailImage?.fileCid ?? String(collectionThumbnail),
			collectionSquareImageMediaType: "image/png",
			collectionTwitterHandle: values.twitterHandle,
			contractName: contractName,
		}
		const nftDetails = {
			nftData: [],
			nftDescription: values.collectionDescription,
			nftEditions: null,
			nftExternalURL: values.collectionWebsiteURL,
			nftName: values.collectionName,
			nftThumbnail: values.nftImage?.fileCid ?? String(nftImage),
			nftTraits: null,
		}
		const dropDetails = {
			dropDescription: "",
			dropMedias: null,
			dropMinters: [],
			dropName: "",
			dropNftType: null,
			dropThumbnail: values.dropThumbnailImage?.fileCid ?? String(dropImage),
			dropTotalMinted: null,
		}
		const phaseDetails = [
			{
				phaseEndDate: Math.floor(
					Number(values.dropEndDateTime?.dateTime) / 1000
				),
				phasePrice: Number(values.mintPrice),
				phaseStartDate: Math.floor(
					Number(values.dropStartDateTime?.dateTime) / 1000
				),
			} as DropPhaseProps,
		]

		try {
			setCollectionState(prev => ({
				...prev,
				isCreating: true,
				isError: false,
			}))

			const response = await flowty.createOpenEdition({
				collectionDetails,
				dropDetails,
				nftDetails,
				phaseDetails,
				txAvailableCallback: (id: string) => setTransactionID(id),
			})
			if (response?.status === 4) {
				setCollectionState(prev => ({
					...prev,
					isCreating: false,
					isSuccess: true,
				}))

				const createAccountEvent = response.events.find(
					(event: { type: string }) =>
						event.type === "flow.AccountCreated" ||
						event.type === "flow.AccountContractAdded"
				)

				if (createAccountEvent) {
					setCollectionPageURL(
						`/collection/${createAccountEvent.data.address}/${contractName}?orderFilters=%7B"all"%3A%7B%7D%7D&sort=%7B"direction"%3A"desc"%2C"listingKind"%3Anull%2C"path"%3A"blockTimestamp"%7D&page=drop`
					)
					setCollectionPageTwitterURL(
						`/collection/${createAccountEvent.data.address}/${contractName}?page=drop`
					)
				}

				await cleanCreatorHubCache()

				setErrMessage(null)

				mixPanelFn(`Successful storefront listing transaction`, {
					collectionCreatedData: {
						collectionDetails,
						dropDetails,
						nftDetails,
						phaseDetails,
					},
				})
				return
			}

			if (
				(response?.status !== 4 && response?.toString()?.includes("Error")) ||
				response?.toString()?.includes("Declined")
			) {
				throw new Error(response.errorMessage)
			}
		} catch (e) {
			if (e instanceof Error) {
				if (e.message.includes("Amount withdrawn must be less")) {
					setErrMessage(
						"Insufficient FLOW balance to complete transaction. Please add .01 FLOW to your wallet and retry."
					)
				}
			}
			setCollectionState(prev => ({
				...prev,
				isCreating: false,
				isError: true,
			}))

			if (e?.toString()?.includes("Popup failed to open")) {
				await Swal.fire({
					background: "#031021",
					color: "#FFFFFF",
					confirmButtonColor: "#FF6969",
					icon: "error",
					showConfirmButton: true,
					text: "Pop-up blocker is enabled. Please allow pop-ups and try again.",
					timer: 5000,
				})
			}
			mixPanelFn(`Error storefront listing transaction`, {
				collectionCreatedData: {
					collectionDetails,
					dropDetails,
					nftDetails,
					phaseDetails,
				},
				err: e,
			})
		}
	}

	const resetModal = (): void => {
		resetForm()
		setCollectionState({
			isCreating: false,
			isError: false,
			isSuccess: false,
		})
		setTransactionID(null)
		setErrMessage(null)
	}

	const redirectToCreatorHub = (): void => {
		//logic for going back to first page in creator hub
		resetModal()
		onCloseModal()
	}

	const directToDropPage = (): void => {
		if (collectionPageURL) {
			navigate(collectionPageURL)
		}
	}

	const directToCreatorHubPage = (): void => {
		navigate("/user/creator-hub")
	}

	const isImageUploaded =
		Boolean(values.nftImage?.fileCid) &&
		Boolean(values.nftThumbnailImage?.fileCid) &&
		Boolean(values.nftBannerImage?.fileCid) &&
		Boolean(values.dropThumbnailImage?.fileCid)

	const modalNavProps: CreatorHubNavProps = useMemo(() => {
		if (loggedIsDapper) {
			return {
				bgColor: "primary",
				disabled: false,
				onClick: signIn,
				text: isLoggingIn ? (
					<div className='spinner w-[14px] h-[14px] sm:w-[20px] sm:h-[20px] border-4 border-black rounded-full border-t-transparent animate-spin'></div>
				) : (
					"Sign In With A Different Wallet"
				),
			}
		}
		if (!isLoggedUser) {
			return {
				bgColor: "primary",
				disabled: false,
				onClick: signIn,
				text: isLoggingIn ? (
					<div className='spinner w-[14px] h-[14px] sm:w-[20px] sm:h-[20px] border-4 border-black rounded-full border-t-transparent animate-spin'></div>
				) : (
					"Sign In"
				),
			}
		}
		const { isCreating, isError, isSuccess } = collectionState
		if (!isCreating && !isError && !isSuccess) {
			return {
				bgColor: "primary",
				disabled: false,
				onClick: !isImageUploaded
					? () => uploadImages()
					: () => submitNewCollection(),
				text: "Accept And Continue",
			}
		}
		if (isCreating) {
			return {
				bgColor: "primary",
				disabled: true,
				onClick: () => {},
				text: "Building Collection",
			}
		}
		if (isSuccess) {
			return [
				{
					bgColor: "white",
					className: "w-[14rem] text-base",
					disabled: false,
					onClick: directToDropPage,
					text: "Visit Drop Page",
				},
				{
					bgColor: "primary",
					className: "w-[14rem] text-base",
					disabled: false,
					onClick: directToCreatorHubPage,
					text: "Creator Hub",
				},
			]
		}
		if (isError) {
			return [
				{
					bgColor: "white",
					className: "w-[14rem] text-lg",
					disabled: false,
					onClick: redirectToCreatorHub,
					text: "Back",
				},
				{
					bgColor: "primary",
					className: "w-[14rem] text-lg",
					disabled: false,
					onClick: !isImageUploaded
						? () => uploadImages()
						: () => submitNewCollection(),
					text: "Try Again",
				},
			]
		}
	}, [
		collectionState,
		loggedIsDapper,
		isLoggedUser,
		submitNewCollection,
		uploadImages,
		values,
	])

	return {
		collectionPageTwitterURL,
		collectionPageURL,
		collectionState,
		directToDropPage,
		errMessage,
		modalNavProps,
		onCloseModal,
		transactionID,
	}
}
