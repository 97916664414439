import React from "react"
import numeral from "numeral"
import { FlowtyTokenIcon } from "../../../FlowtyTokenIcon"
import { NotificationType } from "flowty-common"

export function getNotificationMessage({
	type,
	amount,
	title,
	token,
	wallet,
	loggedUserAddress,
	timeRemaining,
}: {
	type: NotificationType
	amount: number
	title: string
	token: string
	wallet?: string
	loggedUserAddress?: string
	timeRemaining?: string
}): React.ReactNode {
	const formattedTitle = (
		<strong className='text-[#DEDEDE] font-montserrat text-[14px] font-black'>
			{title}
		</strong>
	)

	const tokenIcon = <FlowtyTokenIcon token={token?.toLowerCase()} />

	const formattedAmount = (
		<span className='inline-flex items-center gap-[4px]'>
			{tokenIcon}
			<strong className='text-[#DEDEDE] font-montserrat text-[14px] font-black'>
				{numeral(amount).format("0,0.00")}
			</strong>
		</span>
	)
	switch (type) {
		case NotificationType.StorefrontPurchased:
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Sold {formattedTitle} for {formattedAmount}
				</span>
			)
		case NotificationType.OfferAccepted:
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Your offer of {formattedAmount} on {formattedTitle} was accepted by{" "}
					{wallet}
				</span>
			)
		case NotificationType.OfferReceived:
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					You have received a {formattedAmount} offer on {formattedTitle}
				</span>
			)
		case NotificationType.OfferExpired:
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Your offer for {formattedAmount} on {formattedTitle} has expired
				</span>
			)
		case NotificationType.RentalFunded:
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					{formattedTitle} was rented by {wallet} for {formattedAmount}
				</span>
			)
		case NotificationType.RentalReturned:
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					{formattedTitle} was returned by {wallet}
				</span>
			)
		case NotificationType.RentalSettled: {
			return wallet === loggedUserAddress ? (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					You did not return {formattedTitle} rental on time
				</span>
			) : (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Your rented {formattedTitle} was not returned
				</span>
			)
		}
		case NotificationType.PrivateRental:
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					A private rental listing for {formattedTitle} was created for you
				</span>
			)
		case NotificationType.RentalExpirationWarning:
			return wallet === loggedUserAddress ? (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					{timeRemaining} to return {formattedTitle} or you will forfeit your
					refundable deposit
				</span>
			) : (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Renter has {timeRemaining} to return {formattedTitle}
				</span>
			)
		case NotificationType.LoanFunded:
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					{wallet} funded your {formattedTitle} loan
				</span>
			)
		case NotificationType.LoanRepaid:
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Repaid {formattedAmount} loan on {formattedTitle}
				</span>
			)
		case NotificationType.ListingInvalid:
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					{formattedTitle} Loan listing for {formattedAmount} is no longer valid
				</span>
			)
		case NotificationType.LoanSettled: {
			return wallet === loggedUserAddress ? (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					You did not repay {formattedAmount} loan on {formattedTitle} on time
				</span>
			) : (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Your {formattedAmount} funding of {formattedTitle} was not repaid
				</span>
			)
		}
		default:
			return null
	}
}
