import { FlowtyListingModal, FlowtyPurchaseModal } from "ds-flowty"
import {
	InitiatedTransactionNotification,
	NotificationType,
	OfferCreated,
	OpensearchFlowNFT,
	Order,
	SpotPrice,
	Valuation,
	checkIsLocked,
	nftTypeAndIdToLocationData,
	nftTypeToContractID,
} from "flowty-common"
import { AccountSummaries, LoanRentalFilteredData } from "flowty-sdk"
import { inject, observer } from "mobx-react"
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react"
import { flowty } from "../../config/config"
import { db } from "../../firebase"
import { useTokenSpotPrice } from "../../hooks/data/collections/useTokenSpotPrice"
import { useFallbackImage } from "../../hooks/useFallbackImage"
import { actions as Mixpanel } from "../../util/Mixpanel"
import { TOPSHOT_TYPE, strapiUrl } from "../../util/settings"
import { useModalGetItem } from "./hooks/useModalGetItem"
import { useModalGetValuation } from "./hooks/useModalGetValuation"
import { useModalHybridCustody } from "./hooks/useModalHybridCustody"
import {
	FlowtyModalsContextValues,
	FlowtyModalsProviderProps,
	LostAndFoundModalsData,
	NftDetails,
	SelectCardProps,
} from "./types/FlowtyModalsContextTypes"
import { Log } from "../../util/Log"

const defaultValues: FlowtyModalsContextValues = {
	isViewOnly: false,
	selectCard: async () => {},
}

export const FlowtyModalsContext =
	createContext<FlowtyModalsContextValues>(defaultValues)

const FlowtyModalsProvider: React.FC<FlowtyModalsProviderProps> = ({
	authStore,
	children,
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [selectedNftDetails, setSelectedNftDetails] =
		useState<NftDetails | null>(null)
	const [selectedOffer, setSelectedOffer] = useState<OfferCreated | null>(null)
	const [offerType, setOfferType] = useState<
		"make-offer" | "cancel-offer" | null
	>(null)
	const [singleAction, setSingleAction] = useState<
		"delistLoan" | "delistRental" | "delistSale" | undefined
	>(undefined)
	const [singleOrder, setSingleOrder] = useState<Order | null | undefined>(null)
	const [ownsNFTSelected, setOwnsNFTSelected] = useState<boolean>(false)
	const [initialListingType, setInitialListingType] = useState<
		"loan" | "rent" | "transfer" | undefined
	>(undefined)
	const [singlePurchase, setSinglePurchase] = useState<
		Order | null | undefined
	>(null)
	const [loanRentalActionsData, setLoanRentalActionsData] =
		useState<LoanRentalFilteredData | null>(null)
	const [isLostAndFound, setIsLostAndFound] = useState<boolean>(false)
	const [lostAndFoundTicket, setLostAndFoundTicket] =
		useState<LostAndFoundModalsData>({
			catalogIdentifier: "",
			ticketID: "",
		})

	const {
		cleanItemOrder,
		fetchFlowNFT,
		flowNFT,
		getItem,
		getLoanOrder,
		isOrderLoading,
		orders,
		selectedNft,
	} = useModalGetItem()

	const { cleanValuation, getValuation, isLoadingValuation, valuation } =
		useModalGetValuation()

	const {
		addressesWithCollectionPublic,
		checkHasProvider,
		getNftProviderPathIdentifier,
		getPublicAddresses,
	} = useModalHybridCustody()
	const [collectionDisplayName, setCollectionDisplayName] = useState<
		string | null
	>(null)

	const { collectionAddress, collectionName } = selectedNft?.card || {
		collectionAddress: "",
		collectionName: "",
	}
	const collectionImages = useFallbackImage({
		collectionAddress,
		collectionName,
	})
	const collectionImage = collectionImages[collectionName]

	const spotPrice = useTokenSpotPrice("FLOW")

	const listingType = useMemo(() => {
		if (singlePurchase) {
			return singlePurchase.listingKind === "storefront"
				? "purchase"
				: singlePurchase.listingKind
		}
		if (
			selectedNft?.orders?.[0]?.listingKind === "storefront" ||
			offerType === "make-offer" ||
			loanRentalActionsData
		)
			return "purchase"

		return selectedNft?.orders?.[0]?.listingKind
	}, [selectedNft, offerType, loanRentalActionsData])

	const nftType = selectedNft?.type?.endsWith(".NFT")
		? selectedNft?.type
		: `${selectedNft?.type}.NFT`

	let isLocked = false
	if (nftType === TOPSHOT_TYPE) {
		isLocked = checkIsLocked(selectedNft)
	}

	const collectionIdentifier = `${selectedNftDetails?.contractAddress}.${selectedNftDetails?.contractName}`

	const userHasProvider = useMemo(() => {
		const hasProvider = checkHasProvider(
			authStore?.loggedUser?.addr ?? "",
			selectedNft
		)

		return hasProvider || isLocked
	}, [selectedNft, isLocked])

	const nftProviderPathIdentifier = useMemo(() => {
		return getNftProviderPathIdentifier(
			authStore?.loggedUser?.addr ?? "",
			selectedNft,
			collectionIdentifier
		)
	}, [selectedNft, authStore])

	useEffect(() => {
		cleanItemOrder()
		cleanValuation()

		if (
			!selectedNftDetails?.contractAddress ||
			!selectedNftDetails?.contractName ||
			!selectedNftDetails?.nftID
		)
			return

		const ld = nftTypeAndIdToLocationData(
			selectedNftDetails?.nftType ?? "",
			selectedNftDetails?.nftID ?? ""
		)

		getItem(ld.contract.address, ld.contract.name, ld.nftID, ld.resourceName)
		getValuation(selectedNftDetails)
		fetchFlowNFT(selectedNftDetails.nftID, selectedNftDetails?.nftType ?? "")
	}, [selectedNftDetails])

	useEffect(() => {
		if (selectedNftDetails) {
			getPublicAddresses(
				collectionIdentifier,
				authStore?.loggedUser?.childAccounts || {},
				authStore?.loggedUser?.addr || "",
				authStore?.loggedUser?.accountSummaries || {},
				selectedNftDetails?.contractAddress || "",
				selectedNftDetails?.contractName || ""
			)
		}
	}, [selectedNftDetails])

	useEffect(() => {
		const ownerNFTSelected = selectedNft?.owner

		if (isLostAndFound) {
			setOwnsNFTSelected(true)
			return
		}

		if (!ownerNFTSelected || !authStore?.loggedUser?.addr) {
			setOwnsNFTSelected(false)
			return
		}

		if (authStore.loggedUser.addr === ownerNFTSelected) {
			setOwnsNFTSelected(true)
			return
		}

		if (!authStore?.loggedUser?.childAccounts) {
			setOwnsNFTSelected(false)
			return
		}

		if (ownerNFTSelected in (authStore?.loggedUser?.childAccounts || {})) {
			setOwnsNFTSelected(true)
		}

		// The signed in user owns this nft is they are the owner or if one of their child accounts is the owner
	}, [selectedNft, authStore, isLostAndFound])

	const mixPanelFn = useCallback((action: string, properties: unknown) => {
		Mixpanel.track(action, properties)
	}, [])

	const selectCard = useCallback(
		async ({
			selected,
			initListingType,
			selectedSinglePurchase,
			selectedSingleOffer,
			singleOfferType,
			singleDelistAction,
			singleDelistOrder,
			loanRentalActions,
			lostAndFoundModal,
			lostAndFoundTicketData,
		}: SelectCardProps) => {
			setIsModalOpen(true)
			setSelectedNftDetails(selected)

			if (lostAndFoundModal) {
				setIsLostAndFound(true)
				setLostAndFoundTicket(lostAndFoundTicketData as LostAndFoundModalsData)
				return
			}
			if (loanRentalActions) {
				if (loanRentalActions.type === "loan") {
					const fundingId = await getLoanOrder(
						loanRentalActions?.listingResourceID ?? ""
					)
					loanRentalActions = {
						...loanRentalActions,
						fundingResourceID: fundingId,
					}
				}
				setLoanRentalActionsData(loanRentalActions)
				return
			}
			setInitialListingType(initListingType)
			setSinglePurchase(selectedSinglePurchase)
			selectedSingleOffer
				? setSelectedOffer(selectedSingleOffer)
				: setSelectedOffer(null)
			singleOfferType ? setOfferType(singleOfferType) : setOfferType(null)
			singleDelistAction
				? setSingleAction(singleDelistAction)
				: setSingleAction(undefined)
			singleDelistOrder
				? setSingleOrder(singleDelistOrder)
				: setSingleOrder(null)
		},
		[]
	)

	const getCollectionDisplayName = async (): Promise<void> => {
		const contractID = nftTypeToContractID(nftType)
		try {
			const doc = await db.collection("flowNFTContract").doc(contractID).get()
			if (doc.exists) {
				const data = doc.data()
				if (data?.collectionDisplay?.name) {
					const removeDashesName = data?.collectionDisplay?.name?.replaceAll(
						"-",
						" "
					)
					setCollectionDisplayName(removeDashesName)
				}
			}
		} catch (error) {
			Log(error, nftType, contractID)
		}
	}

	useEffect(() => {
		getCollectionDisplayName()

		const ownerNFTSelected = selectedNft?.owner

		if (!ownerNFTSelected || !authStore?.loggedUser?.addr) {
			setOwnsNFTSelected(false)
			return
		}

		if (authStore.loggedUser.addr === ownerNFTSelected) {
			setOwnsNFTSelected(true)
			return
		}

		if (!authStore?.loggedUser?.childAccounts) {
			setOwnsNFTSelected(false)
			return
		}

		if (ownerNFTSelected in (authStore?.loggedUser?.childAccounts || {})) {
			setOwnsNFTSelected(true)
		}

		// The signed in user owns this nft is they are the owner or if one of their child accounts is the owner
	}, [selectedNft, authStore])

	const onCloseModal = (): void => {
		setIsModalOpen(false)
		setIsLostAndFound(false)
		setLostAndFoundTicket({
			catalogIdentifier: "",
			ticketID: "",
		})

		cleanItemOrder()
		cleanValuation()

		setSelectedNftDetails(null)

		setSelectedOffer(null)
		setSinglePurchase(null)
		setLoanRentalActionsData(null)
	}

	const createTransactionNotification = async (
		data: InitiatedTransactionNotification
	): Promise<void> => {
		if (!authStore?.loggedUser?.addr) {
			return
		}
		await db
			.collection(`/accounts/${authStore.loggedUser.addr}/messages`)
			.add({ ...data, type: NotificationType.Transaction })
	}

	const values = useMemo(
		() => ({
			isViewOnly: false,
			selectCard,
		}),
		[selectCard]
	)

	return (
		<FlowtyModalsContext.Provider value={values}>
			{children}
			{isModalOpen && selectedNft && (
				<>
					{ownsNFTSelected &&
					!loanRentalActionsData &&
					offerType !== "cancel-offer" ? (
						<FlowtyListingModal
							createTransactionNotification={createTransactionNotification}
							collectionDisplayName={collectionDisplayName}
							collectionImage={collectionImage}
							initialListingType={initialListingType}
							valuation={valuation}
							isLoadingValuation={isLoadingValuation}
							isOpen={isModalOpen}
							onClose={onCloseModal}
							openSearchFlowNFT={selectedNft as OpensearchFlowNFT}
							accountSummaries={
								authStore?.loggedUser?.accountSummaries as AccountSummaries
							}
							hasProvider={userHasProvider}
							spotPrice={spotPrice as SpotPrice}
							nftProviderPathIdentifier={nftProviderPathIdentifier}
							flowNFT={flowNFT ?? undefined}
							selectedOffer={selectedOffer as OfferCreated}
							singleAction={singleAction}
							singleOrder={singleOrder ?? undefined}
							strapiUrl={strapiUrl}
							mixPanelFn={mixPanelFn}
							addressesWithCollectionPublic={addressesWithCollectionPublic}
							flowty={flowty}
						/>
					) : (
						<FlowtyPurchaseModal
							isLostAndFound={isLostAndFound}
							lostAndFoundTicketID={lostAndFoundTicket.ticketID}
							lostAndFoundCatalogIdentifier={
								lostAndFoundTicket.catalogIdentifier
							}
							flowNft={flowNFT ?? undefined}
							createTransactionNotification={createTransactionNotification}
							collectionImage={collectionImage}
							singleOffer={offerType ?? undefined}
							offer={selectedOffer as OfferCreated}
							isLoggedUser={Boolean(authStore?.loggedUser?.loggedIn)}
							purchaseType={listingType}
							nftOrders={!singlePurchase ? orders : undefined}
							isLoadingOrders={isOrderLoading}
							singleListing={singlePurchase ?? undefined}
							openSearchFlowNFT={selectedNft as OpensearchFlowNFT}
							accountSummaries={
								authStore?.loggedUser?.accountSummaries as AccountSummaries
							}
							nftProviderPathIdentifier={nftProviderPathIdentifier}
							isOpen={isModalOpen}
							onClose={onCloseModal}
							valuation={valuation as Valuation}
							isLoadingValuation={isLoadingValuation}
							spotPrice={spotPrice as SpotPrice}
							strapiUrl={strapiUrl}
							hasProvider
							mixPanelFn={mixPanelFn}
							addressesWithCollectionPublic={addressesWithCollectionPublic}
							loanRentalActionsData={loanRentalActionsData || undefined}
							flowty={flowty}
						/>
					)}
				</>
			)}
		</FlowtyModalsContext.Provider>
	)
}

const FlowtyModalsProviderAuthStore = inject("authStore")(
	observer(FlowtyModalsProvider)
)

const useFlowtyModalsContext = (): FlowtyModalsContextValues =>
	useContext(FlowtyModalsContext) as FlowtyModalsContextValues

export {
	FlowtyModalsProviderAuthStore as FlowtyModalsProvider,
	useFlowtyModalsContext,
}
