import { format, fromUnixTime, toDate } from "date-fns"
import { FlowtyButton } from "ds-flowty"
import {
	nftTypeAndIdToLocationData,
	OfferCreated,
	OpensearchFlowNFT,
} from "flowty-common"
import { inject, observer } from "mobx-react"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Tooltip } from "react-tooltip"
import { ReactComponent as NoneFoundIcon } from "../../../../../../assets/icons/none-found.svg"
import ViewOnly from "../../../../../../assets/media/hc/viewOnly.png"
import { flowty } from "../../../../../../config/config"
import { useCatalogContext } from "../../../../../../contexts/CatalogContext"
import { useFlowtyModalsContext } from "../../../../../../contexts/FlowtyModalsContext/FlowtyModalsContext"
import { useHybridCustodyContext } from "../../../../../../contexts/HybridCustodyContext"
import { AuthStoreProp } from "../../../../../../stores/AuthStore"
import { Log } from "../../../../../../util/Log"
import { getTimeToDate } from "../../../../../../util/timestamps"
import { ListingIndicatorPill } from "../../../../../../components/Shared/ListingIndicatorPill/ListingIndicatorPill"
import { TokenAmount } from "../../../../../../components/Tokens/tokenDisplays"

interface AssetOffersTableProps extends AuthStoreProp {
	nft: OpensearchFlowNFT
}

const AssetOffersTableComponent: React.FC<AssetOffersTableProps> = ({
	authStore,
	nft,
}) => {
	const { collectionName, collectionAddress, nftId } = useParams()

	const [offers, setOffers] = useState<OfferCreated[]>([])

	const { denylistCache } = useCatalogContext()

	const { selectCard } = useFlowtyModalsContext()

	const selectOffer = useCallback(
		({
			offer,
			singleOfferType,
		}: {
			offer?: OfferCreated
			singleOfferType?: "make-offer" | "cancel-offer"
		}) => {
			selectCard({
				selected: {
					contractAddress: nft.contractAddress,
					contractName: nft.contractName,
					nftID: nft.id,
					nftType: nft.type,
				},
				selectedSingleOffer: offer as OfferCreated,
				singleOfferType,
			})
		},
		[selectCard]
	)

	const { hybridCustodyNFTStatus, iterateAndRunScript } =
		useHybridCustodyContext()

	const userAddresses = authStore?.getAccountSummaryAddresses() ?? []
	const isOwner = userAddresses.includes(nft?.owner || "")

	const nftType = useMemo(
		() => (!nft?.type.endsWith(".NFT") ? `${nft?.type}.NFT` : nft.type),
		[nft]
	)

	const collectionIdentifier = `${nft?.contractAddress}.${nft?.contractName}`
	const hasProvider =
		hybridCustodyNFTStatus?.[nft?.owner || ""]?.[collectionIdentifier]?.length

	const facets = `${collectionAddress}.${collectionName}`
	useEffect(() => {
		iterateAndRunScript(
			[facets],
			authStore?.loggedUser?.childAccounts || {},
			authStore?.loggedUser?.addr || ""
		)
	}, [])

	useEffect(() => {
		if (!collectionAddress || !collectionName || !nftId) return

		const location = nftTypeAndIdToLocationData(nft.type, nftId)

		flowty.api
			.getItem({
				contractAddress: collectionAddress,
				contractName: collectionName,
				nftID: nftId,
				resourceName: location.resourceName,
			})
			.then(res => {
				const sortedOffersUSDValue = res.offers.sort(
					(a: OfferCreated, b: OfferCreated) => b.usdValue - a.usdValue
				)
				setOffers(sortedOffersUSDValue)
			})
			.catch(err => Log({ err }))
	}, [])

	return (
		<div className='flex-1 rounded-[0.5rem] bg-[#606e7d33] w-full'>
			<div className='flex flex-row justify-between w-full p-[1.5rem]'>
				<ListingIndicatorPill symbol='OFFER' />
				{!isOwner &&
					authStore?.loggedUser?.loggedIn &&
					!denylistCache.has(nftType) && (
						<div className='w-[9rem]'>
							<FlowtyButton
								text={"MAKE OFFER"}
								onClick={() => {
									selectOffer({ singleOfferType: "make-offer" })
								}}
								variant={"secondary"}
								bgColor={"white"}
							/>
						</div>
					)}
			</div>
			<div className='overflow-x-auto'>
				<div className='inline-block min-w-full styled-scroll max-h-64 overflow-y-auto px-[1.5rem]'>
					<table className='w-full'>
						<thead className='border-b-[1px] border-[#adb5bd3d]'>
							<tr>
								<th className='h-[4rem] text-left px-[0.75rem] font-montserrat font-bold text-[#DDDDDD] text-xs md:text-[0.875rem]'>
									OFFER
								</th>
								<th className='h-[4rem] text-left px-[0.75rem] font-montserrat font-bold text-[#DDDDDD] text-xs md:text-[0.875rem]'>
									FROM
								</th>
								<th className='h-[4rem] text-left px-[0.75rem] font-montserrat font-bold text-[#DDDDDD] text-xs md:text-[0.875rem]'>
									DATE <span className='hidden md:inline-block'>OFFERED</span>
								</th>
								<th className='h-[4rem] text-left px-[0.75rem] font-montserrat font-bold text-[#DDDDDD] text-xs md:text-[0.875rem]'>
									EXPIRATION
								</th>
								{authStore?.loggedUser?.addr && (
									<th className='h-[4rem] px-[0.75rem] font-montserrat font-bold text-[#DDDDDD] text-xs md:text-[0.875rem] text-center'>
										ACTION
									</th>
								)}
							</tr>
						</thead>
						<tbody>
							{offers?.map((offer, idx) => {
								return (
									<tr
										key={idx}
										className='border-b-[1px] border-[#adb5bd3d] h-[5rem]'
									>
										<td className='px-[0.75rem]'>
											<div className='min-w-[3em]'>
												<TokenAmount
													amount={offer.amount}
													token={offer.paymentTokenName}
													isSmall
													largeText={false}
													justifyStart={true}
												/>
											</div>
										</td>
										<td className='px-[0.75rem] text-[#04E5A3]'>
											<div className='flex flex-col'>
												<Link to={`/user/${offer.storefrontAddress}`}>
													<span className='text-eletric-green-900 text-base hover:underline hover:text-primary'>
														{offer.storefrontAddress}
													</span>
												</Link>
											</div>
										</td>
										<td className='px-[0.75rem] whitespace-nowrap font-bold text-base text-[#D9D9D9]'>
											{format(toDate(offer.blockTimestamp), "yyyy-MM-dd HH:mm")}
										</td>
										<td className='px-[0.75rem] whitespace-nowrap font-bold text-base  text-[#D9D9D9]'>
											{getTimeToDate(fromUnixTime(offer.expiry))}
										</td>
										{authStore?.loggedUser?.addr === offer.storefrontAddress ? (
											<td className='whitespace-nowrap px-[0.75rem] text-sm text-center'>
												<div className='space-x-3'>
													<FlowtyButton
														text={"Cancel"}
														onClick={() =>
															selectOffer({
																offer,
																singleOfferType: "cancel-offer",
															})
														}
														variant={"secondary"}
														bgColor={"white"}
													/>
												</div>
											</td>
										) : isOwner ? (
											<td className='whitespace-nowrap px-[0.75rem] text-sm text-center'>
												{authStore?.loggedUser?.addr === nft.owner ||
												hasProvider ? (
													<div className='flex justify-center space-x-4'>
														<FlowtyButton
															onClick={() => selectOffer({ offer })}
															text={"ACCEPT"}
															variant={"secondary"}
															btnSize={"small"}
														/>
													</div>
												) : (
													<div className='flex gap-2 flex-row-reverse items-center'>
														<Tooltip
															id={`${nft.uuid}-view-only-tooltip`}
															style={{
																backgroundColor: "white",
																color: "#031021",
																fontSize: "0.75rem",
															}}
														/>
														<img
															data-tooltip-id={`${nft.uuid}-view-only-tooltip`}
															data-tooltip-content='This profile has view-only access to this item'
															data-tooltip-place='bottom'
															src={ViewOnly}
															alt='Read Only Icon'
															width={16}
															height={16}
														/>
														<FlowtyButton
															disabled
															text={"Accept"}
															variant={"primary"}
														/>
													</div>
												)}
											</td>
										) : (
											<td className='whitespace-nowrap px-[0.75rem] text-sm text-center'>
												-
											</td>
										)}
									</tr>
								)
							})}
						</tbody>
					</table>
					{!offers.length ? (
						<div className='w-full h-full flex justify-center items-center pt-[1.5rem] flex-col animate-pulse gap-[0.75rem]'>
							<div className='relative mr-5'>
								<NoneFoundIcon />
								<div className='flex space-x-2 left-11 bottom-0 absolute'>
									<div className='w-5 h-5 rounded-full bg-[#B3BBBE]'></div>
									<div className='w-5 h-5 rounded-full bg-[#B3BBBE]'></div>
									<div className='w-5 h-5 rounded-full bg-[#B3BBBE]'></div>
								</div>
							</div>
							<div className='text-white text-montserrat text-[0.875rem]'>
								No Offers Found
							</div>
						</div>
					) : null}
				</div>
			</div>
		</div>
	)
}

export const AssetOffersTable = inject("authStore")(
	observer(AssetOffersTableComponent)
)
