import React from "react"
import {
	BaseNotification,
	InitiatedTransactionNotification,
	LoanNotificationContent,
	NotificationData,
	NotificationType,
	OfferNotificationContent,
	RentalExpirationWarningNotification,
	RentalNotificationContent,
	StorefrontPurchasedNotification,
} from "flowty-common"
import TransactionNotificationItem from "../NotificationItem"
import { StorefrontPurchasedNotificationItem } from "../NotificationItem/StorefrontPurchasedNotificationItem/StorefrontPurchasedNotificationItem"
import { OfferNotificationItems } from "../NotificationItem/OfferNotificationItems/OfferNotificationItems"
import { RentalNotificationItems } from "../NotificationItem/RentalNotificationItems/RentalNotificationItems"
import { RentalExpirationWarningNotificationItem } from "../NotificationItem/RentalNotificationItems/RentalExpirationWarningNotificationItem"
import { LoanNotificationItems } from "../NotificationItem/LoanNotificationItems/LoanNotificationItems"

interface NotificationsListProps {
	notifications: NotificationData[]
	queueMessage?: ({ type, id }: { type: NotificationType; id: string }) => void
	label: string
	loggedUserAddress: string
}

const renderNotificationItem = (
	type: BaseNotification["type"],
	notification: NotificationData,
	loggedUserAddress?: string,
	queueMessage?: ({ type, id }: { type: NotificationType; id: string }) => void
): React.ReactNode | null => {
	switch (type) {
		case "TRANSACTION":
			return (
				<TransactionNotificationItem
					{...(notification as InitiatedTransactionNotification)}
					queueMessage={queueMessage}
				/>
			)
		case NotificationType.StorefrontPurchased:
			return (
				<StorefrontPurchasedNotificationItem
					{...(notification as StorefrontPurchasedNotification)}
				/>
			)
		case NotificationType.OfferAccepted:
		case NotificationType.OfferReceived:
		case NotificationType.OfferExpired:
			return (
				<OfferNotificationItems
					{...(notification as OfferNotificationContent)}
					queueMessage={queueMessage}
				/>
			)
		case NotificationType.RentalFunded:
		case NotificationType.RentalReturned:
		case NotificationType.RentalSettled:
		case NotificationType.PrivateRental:
			return (
				<RentalNotificationItems
					{...(notification as RentalNotificationContent)}
					loggedUserAddress={loggedUserAddress}
					queueMessage={queueMessage}
				/>
			)
		case NotificationType.RentalExpirationWarning:
			return (
				<RentalExpirationWarningNotificationItem
					{...(notification as RentalExpirationWarningNotification)}
					loggedUserAddress={loggedUserAddress}
				/>
			)
		case NotificationType.LoanFunded:
		case NotificationType.LoanSettled:
		case NotificationType.LoanRepaid:
		case NotificationType.ListingInvalid:
			return (
				<LoanNotificationItems
					{...(notification as LoanNotificationContent)}
					loggedUserAddress={loggedUserAddress}
					queueMessage={queueMessage}
				/>
			)
		default:
			return null
	}
}

const NotificationsList: React.FunctionComponent<NotificationsListProps> = ({
	notifications,
	queueMessage,
	label,
	loggedUserAddress,
}) => {
	if (notifications.length <= 0) {
		return null
	}

	return (
		<div className='flex flex-col'>
			<span className='text-[#DEE2E6] text-[14px] font-normal mb-[16px]'>
				{label}
			</span>
			{notifications.map((notification, index) => (
				<div
					key={`${notification.id}-${index}`}
					className={`${
						index === notifications.length - 1 ? "mb-0" : "mb-[24px]"
					}`}
				>
					{renderNotificationItem(
						notification.type,
						notification,
						loggedUserAddress,
						queueMessage
					)}
				</div>
			))}
		</div>
	)
}

export default NotificationsList
