/* eslint-disable sort-keys-fix/sort-keys-fix */
import {
	BaseNotification,
	ChildAccountDisplay,
	FlowNFTContract,
	NotificationData,
} from "flowty-common"
import { UserData } from "../components/MyAccountMenu/MyAccountMenu"

interface GlobalSearchHit<T> {
	score: number
	data: T
}

export interface GlobalSearchResponse {
	contracts: GlobalSearchHit<FlowNFTContract>[]
}

export type NavItemName = "Marketplace" | "Activity" | "Create"

export const navItems: Record<NavItemName, string> = {
	Marketplace: "/marketplace",
	Activity: "/activity",
	Create: "/create",
}

interface NotificationQueueFnProps {
	type: BaseNotification["type"]
	id: string
}

interface NotificationProps {
	notifications: NotificationData[]
	hasMore: boolean
	fetchMoreNotifications: () => void
	queueNotification?: ({ type, id }: NotificationQueueFnProps) => void
	loadingNotifications: boolean
}

export interface FlowtyNavbarProps {
	notificationData: NotificationProps
	isLandingPage: boolean
	isCreatePage?: boolean
	isLogged: boolean
	authLoading: boolean
	userAvatar?: string
	logUser: () => void
	selectedItem?: NavItemName
	registerHeight?: (height: number) => void
	bgColor?: string
	loggedUserAddress?: string
	disconnectWallet: () => void
	user?: UserData
	isDapper?: boolean
	childAccounts?: ChildAccountDisplay[]
}
