import {
	FLOW_SCAN_TX_MAINNET_URL,
	FLOW_SCAN_TX_TESTNET_URL,
} from "flowty-common"

const parseEnv = (key: string): string => {
	const val = process.env[key]
	if (val === undefined) {
		throw new Error(`Missing environment variable: ${key}`)
	}
	return val
}

export const ACCESS_API = parseEnv("REACT_APP_ACCESS_API")
export const NETWORK = ACCESS_API?.includes("mainnet")
	? "mainnet"
	: ACCESS_API?.includes("testnet")
	? "testnet"
	: "emulator"

export const IS_CREATOR_HUB_ENABLED = true
export const APP_NAME = process.env.REACT_APP_FIREBASE_PROJECT_ID
export const IS_PROD = APP_NAME === "flowty-prod"
export const IS_MAINNET = ACCESS_API?.includes("mainnet")

export const FLOW_REWARDS_TYPE = IS_MAINNET
	? "A.a45ead1cf1ca9eda.FlowRewards.NFT"
	: "A.2e7cfb413f04382f.FlowRewards.NFT"

export const isFlowRewards = (nftType: string): boolean => {
	return nftType === FLOW_REWARDS_TYPE
}

export const OFFERS_ENABLED = true
export const COLLECTION_PAGE_ENABLED = true

export const MINIMUM_LOAN_VALUE = IS_PROD ? 5 : 0.1
//Repayment can not exceed 250000 but needs to be above max loan value * 1.005 -> 250000 / 1.005 => 248500
export const MAXIMUM_LOAN_VALUE = 248_500
export const MAXIMUM_LOAN_DURATION = 150
export const MINIMUM_LOAN_DURATION = IS_PROD ? 7 : 0
export const MAXIMUM_LOAN_REPAYMENT = 250_000

export const MINIMUM_RENTAL_FEE = IS_PROD ? 1 : 0.01
export const MAXIMUM_RENTAL_FEE = 50_000
export const MINIMUM_RENTAL_DEPOSIT = MINIMUM_RENTAL_FEE
export const MAXIMUM_RENTAL_DEPOSIT = 1_000_000
export const MAXIMUM_RENTAL_DURATION = 375
export const MINIMUM_RENTAL_DURATION = IS_PROD ? 2 : 0

export const MAX_NAME_CHARACTERS = 20
export const DEFAULT_LISTING_EXPIRATION = 60 * 60 * 24 * 30 // 30 days
export const IS_FLOW_EMULATOR =
	ACCESS_API?.includes("localhost") || ACCESS_API?.includes("127.0.0.1")
export const FLOWTY_CONTRACT_ADDR =
	process.env.REACT_APP_ADDRESS_FLOWTY_CONTRACT
export const FLOWTYV2_CONTRACT_ADDR =
	process.env.REACT_APP_ADDRESS_FLOWTYV2_CONTRACT || FLOWTY_CONTRACT_ADDR || ""

export const IS_CRESCENDO = true

export const baseURL = process.env.REACT_APP_BASE_URL
export const apiURL = parseEnv("REACT_APP_API_URL")

export const strapiUrl = parseEnv("REACT_APP_STRAPI_URL")
export const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN

export const nftInfoURL = process.env.REACT_APP_FUNC_NFT_INFO || ""
export const stage = process.env.REACT_APP_STAGE
export const flowscanBaseURL = IS_MAINNET
	? FLOW_SCAN_TX_MAINNET_URL
	: FLOW_SCAN_TX_TESTNET_URL
export const sentryAPI = process.env.REACT_APP_SENTRY_API
export const defaultNftPageSize = 24

export const FLOWTY_FEE_RENTAL_TAX = 0.05
export const FLOWTY_LISTING_SUSPENSION_TIME = 0

export const DEFAULT_TOKEN_TYPE = "USDC"

export const STOREFRONT_ENABLED = true
export const NFT_CATALOG_ADDRESS = process.env.REACT_APP_NFT_CATALOG

export const NFT_CONTRACT_ADDRESS = IS_MAINNET
	? "0x1d7e57aa55817448" // mainnet
	: IS_FLOW_EMULATOR
	? "0xf8d6e0586b0a20c7" // emulator
	: "0x631e88ae7f1d7c20" // testnet

export const HYBRID_CUSTODY_ADDRESS = IS_MAINNET
	? "0xd8a7e05a7ac670c0" // mainnet
	: IS_FLOW_EMULATOR
	? "0xf8d6e0586b0a20c7" // emulator
	: "0x294e44e1ec6993c6" // testnet

export const TOPSHOT_ADDRESS = IS_MAINNET
	? "0x0b2a3299cc857e29"
	: IS_FLOW_EMULATOR
	? "0xf8d6e0586b0a20c7"
	: "0x877931736ee77cff"

export const TRANSACTION_TYPE_ADDRESS = IS_MAINNET
	? "0xccd763254ec95a9e"
	: IS_FLOW_EMULATOR
	? "0xf8d6e0586b0a20c7"
	: "0x86d1c2159a5d9eca"

export const showOfferEvents = true

export const TOPSHOT_TYPE = `A.${TOPSHOT_ADDRESS.substring(2)}.TopShot.NFT`
