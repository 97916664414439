import * as React from "react"
import { useCallback } from "react"
import { NavbarDesktop } from "./components/NavbarDesktop"
import { NavbarLaptop } from "./components/NavbarLaptop"
import { NavbarMobile } from "./components/NavbarMobile"
import { NavbarTablet } from "./components/NavbarTablet"
import { useBreakpoint } from "../utils/useBreakpoint/useBreakpoint"
import { FlowtyNavbarProps } from "./types/NavbarTypes"

export const FlowtyNavbar: React.FunctionComponent<FlowtyNavbarProps> = ({
	isLandingPage,
	isCreatePage,
	isLogged,
	notificationData,
	authLoading,
	userAvatar,
	logUser,
	selectedItem,
	registerHeight,
	loggedUserAddress,
	disconnectWallet,
	childAccounts,
	isDapper,
	user,
}) => {
	const breakpoint = useBreakpoint()

	const [bgBlurLanding, setBgBlurLanding] = React.useState<string>(
		isCreatePage ? "bg-transparent" : "bg-[#04070B]"
	)
	React.useEffect(() => {
		const handleScroll = (): void => {
			const scrollPosition = window.scrollY || window.pageYOffset
			if (scrollPosition === 0) {
				if (isLandingPage) {
					setBgBlurLanding("bg-blur-light")
				} else {
					setBgBlurLanding("bg-[#04070B]")
				}
			} else {
				setBgBlurLanding("bg-blur")
			}
		}

		window.addEventListener("scroll", handleScroll)

		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
	}, [])

	const isMarketplacePage = window.location.pathname.includes("/marketplace")

	const navbarProps = {
		authLoading,
		bgColor: bgBlurLanding,
		childAccounts,
		disconnectWallet,
		isDapper,
		isLandingPage,
		isLogged,
		isMarketplacePage,
		logUser,
		loggedUserAddress,
		notificationData,
		registerHeight,
		selectedItem,
		user,
		userAvatar,
	}

	const returnFlowtyNavbarBasedOnScreenSize = useCallback(() => {
		switch (breakpoint) {
			case "xs":
				return <NavbarMobile {...navbarProps} />
			case "mobile":
				return <NavbarMobile {...navbarProps} />
			case "tablet":
				return <NavbarTablet {...navbarProps} />
			case "laptop":
				return <NavbarLaptop {...navbarProps} />
			default:
				return <NavbarDesktop {...navbarProps} />
		}
	}, [breakpoint, navbarProps])

	return returnFlowtyNavbarBasedOnScreenSize()
}
