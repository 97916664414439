import {
	FLOW_SCAN_TX_MAINNET_URL,
	FLOW_SCAN_TX_TESTNET_URL,
} from "flowty-common"
import { Flowty } from "flowty-sdk"
import {
	PropsWithChildren,
	createContext,
	useContext,
	useMemo,
	useState,
} from "react"
import {
	DropStateType,
	FlowtyDropModalFormType,
} from "../types/FlowtyDropModalTypes"
import { DropMintFnBuilder } from "../utils/DropMintModalFnBuilder"

interface NavPropsType {
	text: string
	onClick: () => void
	disabled?: boolean
	bgColor: "primary" | "danger" | "white"
}

type NavProps = NavPropsType | NavPropsType[] | undefined

interface FlowtyDropModalContextValues {
	error: {
		drop: boolean
	}
	isFormError: {
		drop: boolean
	}
	isLoading: {
		drop: boolean
	}
	onCloseModal: () => void
	resetModal: () => void
	transactionExplorerLink: string | null
	dropPageLink: string | null
	sealed: {
		drop: boolean
	}
	submitTransaction: () => void
	modalNavbarProps: NavProps
	maxPerMint: number
	contractName: string
	collectionDisplayName: string
	showNoFundsMessage?: boolean
	setShowNoFundsMessage?: (value: boolean) => void
	dropStartDateFormat?: string
}

const defaultContext: FlowtyDropModalContextValues = {
	collectionDisplayName: "",
	contractName: "",
	dropPageLink: "",
	dropStartDateFormat: "",
	error: {
		drop: false,
	},
	isFormError: {
		drop: false,
	},
	isLoading: {
		drop: false,
	},
	maxPerMint: 10,
	modalNavbarProps: undefined,
	onCloseModal: () => {},
	resetModal: () => {},
	sealed: {
		drop: false,
	},
	submitTransaction: () => {},
	transactionExplorerLink: "",
}

export const FlowtyDropModalContext =
	createContext<FlowtyDropModalContextValues>(defaultContext)

interface FlowtyDropModalContextProps extends PropsWithChildren {
	onClose: () => void
	resetForm: () => void
	contractAddress: string
	contractName: string
	collectionDisplayName: string
	dropID: string
	phasePrice: number
	mintCount: number
	maxPerMint: number
	nftIdentifier: string
	flowty: Flowty
	updateMintCount?: (value: number) => void
	showNoFundsMessage?: boolean
	dropStartDateFormat?: string
}

export const FlowtyDropModalContextProvider: React.FC<
	FlowtyDropModalContextProps
> = ({
	children,
	onClose,
	resetForm,
	updateMintCount,
	showNoFundsMessage,
	maxPerMint,
	collectionDisplayName,
	contractAddress,
	contractName,
	dropID,
	phasePrice,
	mintCount,
	nftIdentifier,
	flowty,
	dropStartDateFormat,
}) => {
	const [transactionID, setTransactionID] = useState<string | null>(null)
	const [error, setError] = useState<DropStateType>({
		drop: false,
	})

	const [isLoading, setIsLoading] = useState<DropStateType>({
		drop: false,
	})

	const [sealed, setSealed] = useState<DropStateType>({
		drop: false,
	})

	const [isFormError, setIsFormError] = useState<DropStateType>({
		drop: false,
	})

	const isMainnet = flowty.config.network === "mainnet"
	const listingBaseURL = isMainnet ? "flowty.io" : "testnet.flowty.io"
	const dropPageLink = useMemo(() => {
		return `https://${listingBaseURL}/collection/${contractAddress}/${contractName}/?page=drop`
	}, [contractAddress, contractName])

	const transactionExplorerLink = useMemo(() => {
		if (!transactionID) return null
		const flowscanBaseURL =
			flowty.config.network === "mainnet"
				? FLOW_SCAN_TX_MAINNET_URL
				: FLOW_SCAN_TX_TESTNET_URL
		return `${flowscanBaseURL}/${transactionID}`
	}, [transactionID])

	const setFormError = (): void => {
		setIsFormError((prev: DropStateType) => ({ ...prev, ["drop"]: true }))
	}

	const submitTransaction = async (): Promise<void> => {
		if (mintCount < 1) {
			setFormError()
			return
		}
		DropMintFnBuilder({
			commissionAddress: flowty.config.contractAddresses.FlowtyDrops,
			contractAddress,
			contractName,
			dropID,
			dropPhaseIndex: 0,
			flowty,
			mixPanelFn: () => {},
			nftIdentifier,
			numToMint: mintCount,
			paymentIdentifier: flowty.config.getIdentifier("FlowToken", "Vault"),
			paymentReceiverPath: "flowTokenReceiver",
			paymentStoragePath: "flowTokenVault",
			setError,
			setIsFormError,
			setIsLoading,
			setIsSealed: setSealed,
			totalCost: mintCount * phasePrice,
			txAvailableCallback: (txID: string) => {
				setTransactionID(txID)
			},
		})
	}

	const FilteredProfilePageUrl = useMemo(() => {
		const pageOrigin = window.location.origin
		const baseUrl = `${pageOrigin}/user/profile?collectionFilters=`
		const key = `${contractAddress}.${contractName}`
		const filterObject = { [key]: {} }

		const encodedFilters = encodeURIComponent(JSON.stringify(filterObject))
		const completeUrl = baseUrl + encodedFilters
		return completeUrl
	}, [contractAddress])

	const redirectToFilteredProfilePage = (): void => {
		window.open(FilteredProfilePageUrl, "_blank")
	}

	const onCloseModal = (): void => {
		if (!isLoading.drop) {
			setError({
				drop: false,
			})

			setIsFormError({
				drop: false,
			})
			setSealed({
				drop: false,
			})
		}
		resetForm()
		onClose()
	}

	const resetModal = (): void => {
		setError({
			drop: false,
		})

		setIsFormError({
			drop: false,
		})

		setIsLoading({
			drop: false,
		})

		setSealed({
			drop: false,
		})
		setTransactionID(null)
	}

	const resetFormAndModal = (): void => {
		if (updateMintCount) {
			updateMintCount(1)
		}
		resetModal()
		resetForm()
	}

	const modalNavbarProps: NavProps = useMemo(() => {
		if (!error.drop && !isLoading.drop && !sealed.drop) {
			return {
				bgColor: "primary",
				disabled: (mintCount > maxPerMint || showNoFundsMessage) ?? false,
				onClick: submitTransaction,
				text: "Confirm Purchase",
			}
		}
		if (isLoading.drop) {
			return {
				bgColor: "primary",
				disabled: true,
				onClick: () => {},
				text: "Purchasing",
			}
		}
		if (sealed.drop) {
			return [
				{
					bgColor: "white",
					className: "w-[14rem] text-base",
					disabled: false,
					onClick: resetFormAndModal,
					text: "Buy More",
				},
				{
					bgColor: "primary",
					className: "w-[14rem] text-base",
					disabled: false,
					onClick: redirectToFilteredProfilePage,
					text: "View NFT",
				},
			]
		}
		if (error.drop) {
			return [
				{
					bgColor: "white",
					className: "w-[14rem] text-lg",
					disabled: false,
					onClick: resetModal,
					text: "Back",
				},
				{
					bgColor: "primary",
					className: "w-[14rem] text-lg",
					disabled: false,
					onClick: submitTransaction,
					text: "Try Again",
				},
			]
		}
	}, [isLoading, error, sealed, submitTransaction])

	return (
		<FlowtyDropModalContext.Provider
			value={{
				collectionDisplayName,
				contractName,
				dropPageLink,
				dropStartDateFormat,
				error,
				isFormError,
				isLoading,
				maxPerMint,
				modalNavbarProps,
				onCloseModal,
				resetModal,
				sealed,
				showNoFundsMessage,
				submitTransaction,
				transactionExplorerLink,
			}}
		>
			{children}
		</FlowtyDropModalContext.Provider>
	)
}

export const useFlowtyDropModalContext = () =>
	useContext(FlowtyDropModalContext) as FlowtyDropModalContextValues
